<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Payment Method</span>
                <span class="headline" v-else>Create New Payment Method</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Payment Method Code*" :readonly="formType === 'UPDATE'" v-model="form.code" required hint="one word, camel case *PaymentMethodCode*" :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Payment Method Name*" v-model="form.name" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-select :items="currencyList" label="Currency*" v-model="form.currency" required :rules="[rules.requiredSelection]"></v-select>
                                <v-text-field v-if="form.currency === 'OTHERS'" label="Currency*" v-model="form.customCurrency" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-select :items="walletSystems" item-text="displayName" item-value="code" label="Wallet System*" v-model="form.walletSystem" required :rules="[rules.requiredField]" hint="The wallet system the payment method belongs to">
                                </v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Tenant*" v-model="form.tenant" hint="Tenant identifier if payment method belongs to multi tenant Organization" required :rules="[rules.requiredField]" clearable></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Endpoint URL*" v-model="form.endpointUrl" required hint="callback URL" :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="formType === 'UPDATE' && hasPermission('AUTH_UPDATE_PAYMENTMETHOD_SECRET')">
                                <v-text-field label="Secret Key*" v-model="form.secretKey" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                                <v-select :items="[3000, 5000, 10000, 15000, 30000, 60000, 300000]" label="Connection Timeout (ms)*" v-model="form.connectionTimeout" required :rules="[rules.requiredField]"></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                                <v-select :items="[3000, 5000, 10000, 15000, 30000, 60000, 300000]" label="Read Timeout (ms)*" v-model="form.readTimeout" required :rules="[rules.requiredField]"></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                                <v-switch v-model="form.isEnabled" :label="`Is Enabled: ${form.isEnabled ? form.isEnabled.toString() : 'false'}`"></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>

import {
    mapGetters
} from 'vuex';

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        walletSystems: Array
    },
    computed: {
      ...mapGetters([
            'authorities'
        ]),
        populatedForm() {
            return {
                code: this.form.code,
                name: this.form.name,
                currency: this.form.currency,
                customCurrency: this.form.customCurrency,
                endpointUrl: this.form.endpointUrl,
                secretKey: this.form.secretKey,
                connectionTimeout: this.form.connectionTimeout,
                readTimeout: this.form.readTimeout,
                isEnabled: this.form.isEnabled,
                formType: this.formType,
                tenant: this.form.tenant,
                walletSystem: this.form.walletSystem
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            currencyList: ['PHP', 'POINTS', 'OTHERS'],
            form: {},
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-payment-option', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    code: undefined,
                    name: undefined,
                    currency: undefined,
                    customCurrency: undefined,
                    endpointUrl: undefined,
                    secretKey: undefined,
                    connectionTimeout: '3000',
                    readTimeout: '15000',
                    tenant: "DEFAULT",
                    isEnabled: false,
                    walletSystem: undefined
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    code: this.selectedItem.code,
                    name: this.selectedItem.name,
                    currency: this.selectedItem.currency ? this.currencyList.includes(this.selectedItem.currency) ? this.selectedItem.currency : 'OTHERS' : undefined,
                    customCurrency: this.selectedItem.currency,
                    endpointUrl: this.selectedItem.endpointUrl,
                    secretKey: this.selectedItem.secret,
                    connectionTimeout: this.selectedItem.connectTimeout,
                    readTimeout: this.selectedItem.readTimeout,
                    isEnabled: this.selectedItem.isEnabled,
                    tenant: this.selectedItem.tenant ?? "DEFAULT",
                    walletSystem: this.selectedItem.walletSystem ? this.selectedItem.walletSystem.code : undefined
                }
            }
        }
    }
}
</script>
